import React from 'react';
import LoginForm from "../../components/login-form";
import Logo from "../../../common/components/logo";

const LoginView: React.FC = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div
                className="w-full md:max-w-md h-screen md:h-fit p-8 md:p-16 flex flex-col justify-center bg-white rounded-lg shadow-md">
                <Logo size="md"/>
                <h2 className="text-2xl font-bold text-center mb-16 text-dark">Zaloguj się</h2>
                <LoginForm/>
            </div>
        </div>
    );
};

export default LoginView;
