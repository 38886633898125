import useAuth from "../modules/auth/hooks/useAuth";

const MainLayer = () => {
    const {logout} = useAuth();

    return (
        <div className='flex flex-col justify-center items-center p-5'>
            <span className='font-bold text-red-900 mb-3'>Nic tu po Tobie</span>
            <button
                onClick={logout}
                className="mb-6 max-w-64 w-full py-2 px-4 bg-red-600 text-white font-semibold rounded-lg shadow-md
            hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
                Wyloguj się maniek
            </button>
        </div>
    )
}

export default MainLayer
