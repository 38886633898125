import TextFormField from "../../core/components/forms/text-form-field";
import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import useAuth from "../hooks/useAuth";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import PrimaryButton from "../../common/components/primary-button";
import CheckboxFormField from "../../core/components/forms/checkbox-form-field";

const schema = yup.object({
    email: yup
        .string()
        .required(),
    password: yup
        .string()
        .required(),
    rememberLogin: yup
        .boolean(),
}).required();

const LoginForm = () => {
    const {login, loading} = useAuth();

    const methods = useForm({
        resolver: yupResolver(schema),
        delayError: 100,
        disabled: loading,
    });

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = methods.getValues();
        login(body.email, body.password).then()
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => handleLogin(e)} className='flex flex-col gap-y-4'>
                <TextFormField name='email' label='Adres e-mail'/>
                <TextFormField name='password' label='Hasło' type='password'/>
                <span className='flex justify-between mb-2'>
                    <CheckboxFormField name='rememberLogin' label='Zapamiętaj hasło' disabled/>
                    <Link to='/forgot-password'
                          className='text-right w-full block text-sm text-link hover:text-primary_700  font-medium cursor-pointer'>
                        Nie pamiętasz hasła?
                    </Link>
                </span>
                <PrimaryButton loading={loading} type='submit'>Zaloguj się</PrimaryButton>
                <Link to='/register' className='mt-5 text-center w-full block text-sm cursor-pointer'>
                    Nie masz jeszcze konta?
                    <span className=' ml-1 text-link font-medium hover:text-primary_700'>
                        Załóż konto
                    </span>
                </Link>

            </form>
        </FormProvider>
    )
}

export default LoginForm
