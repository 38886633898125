import * as yup from "yup";
import TextFormField from "../../core/components/forms/text-form-field";
import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import useAuth from "../hooks/useAuth";
import {yupResolver} from "@hookform/resolvers/yup";
import {RegisterBody} from "../types/register-body.interface";
import PrimaryButton from "../../common/components/primary-button";


const schema = yup.object({
    email: yup
        .string()
        .email('Niepoprawny adres email')
        .required('Email jest wymagany'),
    password: yup.string()
        .required('Hasło jest wymagane')
        .min(8, 'Hasło nie może być krótsze niż 8 znaków')
        .max(255, 'Hasło nie może być dłuższe niż 255 znaków')
        .matches(/[a-z]/, 'Hasło musi zawierać co najmniej jedną małą literę')
        .matches(/[A-Z]/, 'Hasło musi zawierać co najmniej jedną dużą literę')
        .matches(/[0-9]/, 'Hasło musi zawierać co najmniej jedną cyfrę')
        .matches(/[@$!%*?&#]/, 'Hasło musi zawierać co najmniej jeden znak specjalny'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), undefined], 'Hasła muszą się zgadzać')
        .required('Powtórz hasło jest wymagane'),
}).required();

const RegisterForm = () => {
    const {register, loading} = useAuth();

    const methods = useForm<RegisterBody>({
        resolver: yupResolver(schema),
        mode: "onBlur",
    });

    const handleRegister = (data: RegisterBody) => {
        register(data.email, data.password)
            .then()
            .finally();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleRegister)} className='flex flex-col gap-y-4'>
                <TextFormField name='email' label='Adres e-mail'/>
                <TextFormField name='password' label='Hasło' type='password'/>
                <TextFormField name="confirmPassword" label="Powtórz hasło:" type="password"/>
                <PrimaryButton loading={loading} type='submit'>Załóż konto</PrimaryButton>
                <Link to='/login' className='mt-5 text-center w-full block text-sm cursor-pointer'>
                    Masz już konto?
                    <span className=' ml-1 text-link font-medium hover:text-primary_700'>
                        Zaloguj się !
                    </span>
                </Link>
            </form>
        </FormProvider>
    )
}

export default RegisterForm
