import React, {createContext, useState} from 'react';
import {AuthContextType} from "../types/authContextType.interface";
import {AuthProviderProps} from "../types/authProviderProps.interface";
import AuthService from "../services/auth.service";
import {AxiosError} from "axios";
import UserApiService from "../../core/services/user-api.service";
import {useNavigate} from "react-router-dom";
import LocalStorageService from "../../core/services/local-storage.service";
import {LocalStorageKeys} from "../../core/types/local-storage-keys.interface";
import {ResetPasswordBodyRequest} from "../types/reset-email-body.interface";

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);
    const navigate = useNavigate();

    const login = async (email: string, password: string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AuthService.authenticate(email, password)
            .then((): void => {
                setIsAuthenticated(true);
            })
            .catch((err): void => {
                setError(err);
                setIsAuthenticated(false);
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const register = async (email: string, password: string) => {
        setLoading(true);
        setError(null);

        return await UserApiService.registerAccount(email, password)
            .then(() => {
                navigate('/login', {replace: true})
            })
            .catch((err): void => {
                setError(err);
            })
            .finally((): void => {
                setLoading(false)
            })

    };

    const logout = () => {
        setIsAuthenticated(false);
        AuthService.logout();
    };

    const getTokenToResetPassword = async (email: string) => {
        setLoading(true);
        setError(null);

        return await AuthService.getTokenResetPassword(email)
            .catch((err): void => {
                setError(err);
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const resetPassword = async (token: string, password: string): Promise<void> => {
        setLoading(true);
        setError(null);

        const body: ResetPasswordBodyRequest = {
            email: LocalStorageService.get(LocalStorageKeys.EMAIL_RESET_PASSWORD),
            token,
            password
        }

        return await AuthService.resetPassword(body)
            .then((): void => {
                navigate('/login', {replace: true})
            })
            .catch((err): void => {
                setError(err);
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    return (
        <AuthContext.Provider
            value={{isAuthenticated, login, logout, getTokenToResetPassword, resetPassword, register, loading, error}}>
            {children}
        </AuthContext.Provider>
    )
};

export {AuthContext, AuthProvider}
