import React from 'react';
import {useFormContext} from "react-hook-form";

interface TextFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    type?: string;
    className?: string;
}

const TextFormField: React.FC<TextFormFieldProps> = (props: TextFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}} = useFormContext();
    const {name, label, type = 'text', className = '',...rest} = props

    return (
        <div className="mb-2">
            <label htmlFor={name} className="block text-gray-700">{label}</label>
            <input
                type={type}
                id={name}
                {...register(name)}
                className={`w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 ${errors[name] ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-primary_400'} ${className}`}
                disabled={isSubmitting}
                {...rest}
            />
            {errors[name] && <p className="text-red-500 text-sm">{'' + errors![name]!.message}</p>}
        </div>
    );
};

export default TextFormField;
