import ValidationError from "./validation-error.service";
import {ErrorHandler, ErrorHandlers, StatusCode} from "../types/error-handler.interface";
import {AxiosError} from "axios";
import {ValidationErrors} from "../types/validation-errors";

class ErrorHandling {
    public handlers: ErrorHandlers = {};
    public validationErrorHandler: ValidationError | null = null;

    constructor() {
    }

    addHandler(statusCode: StatusCode, handler: ErrorHandler): void {
        this.handlers[statusCode] = handler;
    }

    setValidationErrorHandler(handler: any): void {
        this.validationErrorHandler = handler;
    }

    handle(error: AxiosError): void {
        let status = error.response ? error.response.status : null;
        if (status && this.handlers[status]) {
            this.handlers[status](error);
        } else {
            console.error('Unhandled error:', error);
        }
    }
}

const errorHandling: ErrorHandling = new ErrorHandling();

errorHandling.addHandler(404, (): void => {
    window.location.href = '/404';
});

errorHandling.addHandler(422, (error: AxiosError): void => {
    if (errorHandling.validationErrorHandler && error.response) {
        const errors = error.response?.data as ValidationErrors
        errorHandling.setValidationErrorHandler(new ValidationError(errors));
    }
    console.error('Validation error:', error.response?.data);
});

export default errorHandling;
