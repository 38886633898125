import ApiService from "../../core/services/api.service";
import NotificationService from "./notification.service";

class UserApiService extends ApiService {

    constructor() {
        super();
    }

    public async registerAccount(email: string, password: string): Promise<void> {
        return await this.post<void>('/users/registration', {email, password}, {})
            .then((res) => {
                NotificationService.success('Konto zostało pomyślnie zarejstrowane.')
            });

    }
}

export default new UserApiService();
