import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LoginView from "./modules/auth/views/login/login";
import RegisterView from "./modules/auth/views/register/register";
import NotFound from "./modules/auth/views/not-found/not-found";
import PrivateRoutes from "./modules/auth/components/PrivateRoutes";
import PublicRoutes from "./modules/auth/components/PublicRoutes";
import {AuthProvider} from "./modules/auth/contexts/AuthContext";
import Main from "./layout/main";
import NotificationPrompt from "./modules/core/components/notification/notification-prompt";
import LandingPage from "./layout/landing-page";
import ForgotPasswordView from "./modules/auth/views/forgot-password/forgot-password";
import ResetPasswordView from "./modules/auth/views/reset-password/reset-password";

function App() {
    return (
        <>
            <Router>
                <AuthProvider>
                    <Routes>
                        <Route element={<PublicRoutes/>}>
                            <Route element={<LandingPage/>} path=""/>
                            <Route element={<LoginView/>} path="login"/>
                            <Route element={<RegisterView/>} path="register"/>
                            <Route element={<ForgotPasswordView/>} path="forgot-password"/>
                            <Route element={<ResetPasswordView/>} path="reset-password"/>
                        </Route>
                        <Route element={<PrivateRoutes/>}>
                            <Route element={<Main/>} path="main"/>
                        </Route>
                        <Route element={<NotFound/>} path="*"/>
                    </Routes>
                </AuthProvider>
            </Router>
            <NotificationPrompt/>
        </>
    );
}

export default App;
