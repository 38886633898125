import ApiService, {CustomAxiosRequestConfig} from "../../core/services/api.service";
import {Authentication} from "../types/authentication.interface";
import LocalStorageService from "../../core/services/local-storage.service";
import {LocalStorageKeys} from "../../core/types/local-storage-keys.interface";
import {AuthenticationResponse} from "../types/authentication-response.interface";
import {UserAuthority} from "../types/user-authority.interface";
import {ResetPasswordBodyRequest} from "../types/reset-email-body.interface";

class AuthService extends ApiService {

    private authentication: Authentication | null = this.loadAuthenticationFromLocalStorage();

    constructor() {
        super();
    }

    public async authenticate(email: string, password: string): Promise<string> {
        const authHeader: string = 'Basic ' + btoa(`${email}:${password}`);
        const httpOptions: CustomAxiosRequestConfig = {
            headers: {
                'Authorization': authHeader
            },
            params: {
                frontend: 'application',
            },
            errorMessage: "Błędny login lub hasło."
        };

        return await this.post<AuthenticationResponse>('/authenticate', {}, httpOptions)
            .then((res: AuthenticationResponse): string => {
                this.authentication = this.prepareAuthentication(res);
                LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
                LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
                return "Sukces"
            });

    }

    public async getTokenResetPassword(email: string): Promise<void> {
        return await this.post<void>('/send-password-reset-token', {email}, {isPublic: true})
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.EMAIL_RESET_PASSWORD);
                LocalStorageService.save(LocalStorageKeys.EMAIL_RESET_PASSWORD, JSON.stringify(email));
            });
    }

    public async resetPassword(body: ResetPasswordBodyRequest): Promise<void> {
        return await this.post<void>('/reset-password', body, {
            isPublic: true,
            successMessage:"Twoje hasło zostało zmienione! Możesz się teraz zalogować.",
        })
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.EMAIL_RESET_PASSWORD);
            });
    }

    public logout(): void {
        this.authentication = null;
        LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
    }

    public isAuthenticated(): boolean {
        return !!this.authentication && new Date(this.authentication.token.validUntil) > new Date();
    }

    private loadAuthenticationFromLocalStorage(): Authentication | null {
        const authenticationStringJson: string | null = LocalStorageService.get(LocalStorageKeys.AUTHENTICATION);
        if (authenticationStringJson) {
            return JSON.parse(authenticationStringJson);
        } else {
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
            return null;
        }
    }

    private prepareAuthentication(authenticationResponse: AuthenticationResponse): Authentication {
        return {
            user: {
                id: authenticationResponse.user.id,
                email: authenticationResponse.user.email,
                username: authenticationResponse.user.username,
                firstname: authenticationResponse.user.firstname,
                lastname: authenticationResponse.user.lastname,
                authority: authenticationResponse.user.authority as UserAuthority,
            },
            token: {
                value: authenticationResponse.token,
                validUntil: this.prepareTokenValidUntil(authenticationResponse.tokenValidInMinutes)
            }
        }
    }

    private prepareTokenValidUntil(tokenValidInMinutes: number): Date {
        return new Date(new Date().getTime() + (tokenValidInMinutes - 1) * 60 * 1000);
    }
}

export default new AuthService();
