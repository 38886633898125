import React from 'react';
import { useFormContext } from "react-hook-form";

interface CheckboxFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    className?: string;
}

const CheckboxFormField: React.FC<CheckboxFormFieldProps> = (props: CheckboxFormFieldProps) => {
    const { register, formState: { errors, isSubmitting } } = useFormContext();
    const { name, label, className = '', disabled, ...rest } = props;

    const isDisabled = disabled !== undefined ? disabled : isSubmitting;

    return (
        <div className="mb-2 flex items-center w-full">
            <input
                type="checkbox"
                id={name}
                {...register(name)}
                className={`appearance-none border min-h-4 min-w-4 h-4 w-4 rounded mr-2 shadow-md focus:outline-none checked:bg-primary focus:ring-0 ${errors[name] ? 'border-red-500' : 'border-gray-300'} ${className}`}
                disabled={isDisabled}
                {...rest}
            />
            <label htmlFor={name} className="text-gray-700 text-sm">{label}</label>
            {errors[name] && <p className="text-red-500 text-sm">{'' + errors![name]!.message}</p>}
        </div>
    );
};

export default CheckboxFormField;
